import { createApp } from 'vue'
import App from './App.vue'
import i18n from './lang/index';
const productType = PRODUCT_TYPE;
// 此段代码写入main.js中
import axios from './utils/request.js'

import ElementPlus from 'element-plus'

import VueHighlightJS from 'vue-highlightjs'
import 'highlight.js/styles/atom-one-dark.css'

import './sass-style/common.scss';
import './sass-style/theme.scss';

import 'element-plus/dist/index.css'
import store from './store';

import router from './router/index'
createApp(App).use(i18n).use(VueHighlightJS).use(ElementPlus).use(axios).use(router).use(store).mount('#app')

const productInfo = productType === 'adult' ? {
    platform: 'pc',
    theme: 'dark'
} : {
    platform: 'mobile',
    theme: 'light'
}

window.productType = productType
document.body.setAttribute('data-theme', productInfo.theme)
document.body.setAttribute('data-platform', productInfo.platform)

